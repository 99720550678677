<template>
  <div style="color: #666;font-size: 14px;">

    <div class="tips"><i class="el-icon-warning-outline"> 温馨提示：</i>
      <p>当前已经排除了sys_开头的基础表</p>
      <p>操作1:代码生成以后需要<strong>重启后端SpringBoot服务</strong></p>
      <p>操作2:新生成的后台页面需要在系统的<strong>角色管理</strong>下进行菜单分配,<strong>重新登录</strong></p>
      <p>如果已经生成过对应模块的代码,但是该模块又<strong>新增字段</strong>,需要<strong>重新生成代码</strong></p>
      <p>注意!新生成的代码会<strong>覆盖原来代码</strong>,导致原先写的代码失效!</p>
    </div>

    <div class="tabs-header">
      <el-button size="small" @click="handleCreateTable">新增数据表</el-button>
      <el-button size="small" @click="codeGenerate" v-if="tables.length!==0">生成模块（{{table.comment}}）代码</el-button>
    </div>

    <el-tabs closable @tab-remove="handleTabRemove" @tab-click="getTable" >
      <el-tab-pane :label="table.comment+'('+table.name+')'" v-for="(table,index) in tables" :name="index+''" :key="index">
       <div class="table-header">
         <el-button @click="handleCreateColumn" plain size="mini" >新增字段</el-button>
         <el-popconfirm
             title="确定要删除此表吗？"
             @confirm="deleteTable"
         >
         </el-popconfirm>
       </div>
        <el-table :data="columns"  stripe :header-cell-class-name="'headerBg'">
          <el-table-column prop="name" label="字段名称"></el-table-column>
          <el-table-column prop="type" label="字段类型"></el-table-column>
          <el-table-column label="展示方式">
            <template v-slot="scope">
              <span v-if="scope.row.type==='int'">数字</span>
              <span v-if="scope.row.type==='decimal'">金额</span>
              <span v-if="scope.row.type==='longtext'">富文本</span>
              <span v-if="scope.row.type==='varchar' && !scope.row.name.endsWith('_date') && !scope.row.name.endsWith('_time') && !scope.row.name.endsWith('_img') && !scope.row.name.endsWith('_file')">
                文本
              </span>
              <span v-if="scope.row.name.endsWith('_date')">日期</span>
              <span v-if="scope.row.name.endsWith('_time')">时间</span>
              <span v-if="scope.row.name.endsWith('_img')">图片</span>
              <span v-if="scope.row.name.endsWith('_file')">文件</span>
            </template>
          </el-table-column>
          <el-table-column prop="length" label="字段长度"></el-table-column>
          <el-table-column prop="scale" label="字段精度"></el-table-column>
          <el-table-column prop="comment" label="字段描述"></el-table-column>
          <el-table-column label="字段操作" width="130" align="left">
            <template v-slot:="scope">
              <div v-show="scope.row.name !== 'id'" class="table-row-options-warp">
                <el-popconfirm
                    class="ml-5"
                    confirm-button-text='确定'
                    cancel-button-text='取消'
                    icon="el-icon-info"
                    icon-color="red"
                    title="您确定删除字段吗？"
                    @confirm="deleteColumn(scope.row.name)"
                >
                  <span type="danger" slot="reference">删除</span>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <div>
    </div>
    <el-dialog :destroy-on-close="true" title="新建字段" :visible.sync="dialogFormVisibleColumn" width="25%" >
      <el-form label-width="80px" size="small":model="columnEntity" ref="columnEntity" :rules="ColumnRules">

        <el-form-item label="类型名称" prop="name">
          <el-input v-model="columnEntity.name" autocomplete="off" style="width: 100%">
            <el-select v-model="columnEntity.type" slot="prepend" placeholder="请选择" @change="changeColumn" style="width: 90px;">
              <el-option
                  v-for="item in columnEntityList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name">
              </el-option>
            </el-select>
            <template slot="append">{{columnSuffix}}</template>
          </el-input>
        </el-form-item>

        <el-form-item label="字段描述" prop="comment">
          <el-input v-model="columnEntity.comment" autocomplete="off" style="width: 100%"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleColumn = false">取 消</el-button>
        <el-button type="primary" @click="createColumn">确 定</el-button>
      </div>
    </el-dialog>



    <el-dialog :destroy-on-close="true" title="新建数据库表" :visible.sync="dialogFormVisibleTable" width="30%" >
      <el-form label-width="80px" size="small":model="tableEntity" ref="tableEntity" :rules="TableRules">
        <el-form-item label="表名称" prop="tableName">
          <el-input v-model="tableEntity.tableName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="模块名称" prop="comment">
          <el-input v-model="tableEntity.comment" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleTable = false">取 消</el-button>
        <el-button type="primary" @click="createTable">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "Home",
  data() {
    return {
      columnEntityList:[
        {
          id:1,
          name:'数字',
          type:'int',
          suffix:''
        },
        {
          id:2,
          name:'文本',
          type:'varchar',
          suffix:''
        },
        {
          id:3,
          name:'富文本',
          type:'longtext',
          suffix:'_content'
        },
        {
          id:4,
          name:'日期',
          type:'varchar',
          suffix:'_date'
        },
        {
          id:5,
          name:'时间',
          type:'varchar',
          suffix:'_time'
        },
        {
          id:6,
          name:'图片',
          type:'varchar',
          suffix:'_img'
        },
        {
          id:7,
          name:'文件',
          type:'varchar',
          suffix:'_file'
        },
        {
          id:8,
          name:'金额',
          type:'decimal',
          suffix:''
        },
      ],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      tables:[],
      columns:[],
      tableEntity:{},
      columnEntity:{},
      columnSuffix:'',
      table:{},
      dialogFormVisibleTable:false,
      dialogFormVisibleColumn:false,
      ColumnRules:{
        name: [
          { required: true, message: '请选择类型并输入名称', trigger: 'blur' },
        ],
        comment: [
          { required: true, message: '请输入注释', trigger: 'change' }
        ],
      },
      TableRules:{
        tableName: [
          { required: true, message: '请输入表名称', trigger: 'blur' },
        ],
        comment: [
          { required: true, message: '请输入模块名称', trigger: 'change' }
        ],
      },
    }
  },
  created() {
    this.loadTables()
  },
  methods:{
    async loadTables(){
      await this.request.get("/table").then(res=>{
        this.tables=res.data
        this.getTable()
      })
    },
    changeColumn(){
      this.columnSuffix = this.columnEntityList.find(item => item.name === this.columnEntity.type)?.suffix
    },
    loadColumns(){
      this.request.get("/table/columns/"+this.table?.name).then(res=>{
        this.columns=res.data
      })
    },
    getTable(e){
      this.table=this.tables?.[e?.index || 0]
      this.loadColumns()
    },
    handleCreateTable(){
      this.tableEntity={}
      this.dialogFormVisibleTable=true
    },
    handleCreateColumn(){
      this.columnSuffix=''
      this.columnEntity={}
      this.dialogFormVisibleColumn=true
    },
    createTable(){
      this.$refs['tableEntity'].validate((valid) => {
        if (valid) {
          this.request.post("/table/create",this.tableEntity).then(res=>{
            if (res.code==='200'){
              this.$message.success("创建表成功")
              this.loadTables()
              this.dialogFormVisibleTable=false
            }else {
              this.$message.error(res.msg)
            }
          })
        }
      });
    },
    createColumn(){
      this.$refs['columnEntity'].validate((valid) => {
        if (valid) {
          if (!this.columnEntity.type){
            this.$message.error("请选择字段类型")
            return
          }
          this.columnEntity.tableName=this.table.name
          this.columnEntity.name += this.columnEntityList.find(item => item.name === this.columnEntity.type)?.suffix
          this.columnEntity.type = this.columnEntityList.find(item => item.name === this.columnEntity.type)?.type
          this.request.post("/table/createColumn",this.columnEntity).then(res=>{
            if (res.code==='200'){
              this.$message.success("操作成功")
              this.loadColumns()
              this.dialogFormVisibleColumn=false
            }else {
              this.$message.error(res.msg)
            }
          })
        }
      });
    },
    deleteColumn(name){
      this.request.get("/table/delete",{
        params:{
          tableName:this.table.name,
          column:name
        }
      }).then(res=>{
        if (res.code==='200'){
          this.loadColumns()
          this.$message.success("删除字段成功")
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    deleteTable(name){
      this.request.get("/table/deleteTable/"+name).then(res=>{
        if (res.code==='200'){
          this.$message.success("删除表成功")
          this.loadTables()
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    handleTabRemove(index) {
      const currentTable = this.tables[index]
      this.$confirm('确定要删除此数据表吗，此操作不可逆，请谨慎操作')
          .then(_ => {
            this.deleteTable(currentTable?.name || '')
          })
          .catch(_ => {});
    },
    codeGenerate(){
      this.request.get("/table/codeGenerate",{
        params:{
          tableName:this.table.name,
          modelName:this.table.comment
        }
      }).then(res=>{
        if (res.code==='200'){
          this.$message.success("代码生成成功，请重启后端服务！")
        }else {
          this.$message.error(res.msg)
        }
      })
    },
  }
}
</script>

<style scoped>
.tabs-header,
.table-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;
}
.table-row-options-warp span {
  cursor: pointer;
  color: #409EFF;
}
.table-row-options-warp span:last-child {
  color: #d63031;
}
 >>> .el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.el-tabs {
  box-shadow: none;
  margin: 12px 0;
  border: none;
}
.tips {
  margin-bottom: 12px;
  background-color: #f1f1f1;
  color: #8c939d;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.65;
}
</style>

